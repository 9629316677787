var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Create QR code")])]),_c('v-card-text',[[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true, regex: /^(?=.{8,30}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/ }),expression:"{ required: true, regex: /^(?=.{8,30}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/ }"}],attrs:{"label":"User Name","data-vv-name":"username","error-messages":_vm.errors.collect('username'),"required":""},model:{value:(_vm.qrUser.username),callback:function ($$v) {_vm.$set(_vm.qrUser, "username", $$v)},expression:"qrUser.username"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":0,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Expired at","readonly":""},model:{value:(_vm.qrUser.expired_at),callback:function ($$v) {_vm.$set(_vm.qrUser, "expired_at", $$v)},expression:"qrUser.expired_at"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.qrUser.expired_at),callback:function ($$v) {_vm.$set(_vm.qrUser, "expired_at", $$v)},expression:"qrUser.expired_at"}})],1)],1)]],2),_c('v-card-text',[(_vm.nodes.length>0)?_c('SlVueTree',{ref:"slVueTree",scopedSlots:_vm._u([{key:"toggle",fn:function(ref){
var node = ref.node;
return [(!node.isLeaf)?_c('span',[(node.isExpanded)?_c('div',{attrs:{"id":"chevron-arrow-down"}}):_vm._e(),(!node.isExpanded)?_c('div',{attrs:{"id":"chevron-arrow-right"}}):_vm._e()]):_vm._e()]}},{key:"title",fn:function(ref){
var node = ref.node;
return [(node.data.type == 'fixture')?_c('v-icon',{staticClass:"fixture-icon"},[_vm._v("lightbulb")]):(node.data.type == 'sensor')?_c('v-icon',{staticClass:"fixture-icon"},[_vm._v("sensors")]):_vm._e(),(Object.keys(node.data).length)?_c('v-btn',{staticClass:"group-btn",attrs:{"small":"","flat":"","to":{
          name: node.data.type == 'fixture' || node.data.type == 'sensor' ? 'main-devices-edit':'main-groups-edit', params: {id: node.data.id}}}},[_c('span',[_vm._v(" "+_vm._s(node.title)+" "+_vm._s(node.data.type ? ("(Serial Number: " + (node.data.serial ? node.data.serial : "N/A") + ", Channel: " + (node.data.channels && node.data.channels.length>0 ? node.data.channels : "N/A") + ")") : "")+" ")])]):_c('v-btn',{staticClass:"group-btn",attrs:{"flat":"","small":""}},[_c('span',[_vm._v(_vm._s(node.title))])])]}},{key:"sidebar",fn:function(ref){
          var node = ref.node;
return [(Object.keys(node.data).length && !node.data.type)?_c('v-checkbox',{staticClass:"shrink mr-0 mt-0",on:{"click":function (e){ return _vm.onSelect(e, node); }},model:{value:(node.data.checked),callback:function ($$v) {_vm.$set(node.data, "checked", $$v)},expression:"node.data.checked"}}):_vm._e()]}}],null,false,1192458292),model:{value:(_vm.nodes),callback:function ($$v) {_vm.nodes=$$v},expression:"nodes"}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{on:{"click":_vm.reset}},[_vm._v("Reset")]),_c('v-btn',{attrs:{"disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }