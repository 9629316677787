














































































































import { Component, Vue } from "vue-property-decorator";
import { IQrUserCreate } from "@/interfaces";
import SlVueTree from 'sl-vue-tree';
import { dispatchCreateQrUser, dispatchGetGroups, dispatchGetDevices } from "@/store/main/actions";
import { readProject, readGroups, readDevices } from '@/store/main/getters';

@Component({
  components: {
    SlVueTree,
  },
})
export default class Create extends Vue {
  public valid = false;
  public menu2 = false;
  public qrUser: IQrUserCreate = this.init();
  public id = '';
  public name = '';
  public nodes: any[] = [];
  public childGroups = [];
  public childDevices: any[] = [];

  public roles = [
    // { id: 'admin', name: 'Admin' },
    { id: 'staff', name: 'Staff' },
    { id: 'user', name: 'User' },
  ];

  get randomUsername() {
    return `qr_${(Math.floor(Math.random() * (9999 - 1000 + 1)) + 100)}${(+ new Date())}`;
  }

  get project() {
    return readProject(this.$store);
  }

  get groups() {
    return readGroups(this.$store);
  }

  get devices() {
    return readDevices(this.$store);
  }

  get getProjectId(){
    return Number(this.$router.currentRoute.params.projectId);
  }

  public getGroup(id: any){
    return this.groups.find(x => x.id === id);
  }

  public getDevice(id: any){
    return this.devices.find(x => x['id'] === id);
  }

  public parseGroup(id: any, device_id = null, parent = [])
  {
    if (device_id) {
      const device = this.getDevice(device_id);
      if (!device){
        return undefined;
      }
      return {
        'isLeaf': true,
        'isDraggable': false,
        'title': device['block_name'],
        'data': {
          id: device_id,
          type: device['type'],
          serial: device['serial_number'],
          channels: device['channels'],
          parent: [...parent]
        }
      };
    }
    const group = this.getGroup(id);
    if (!group) {
      return undefined;
    }
    group['data'] = {
      id: id,
      checked: false,
      parent:  [...parent]
    }
    // group['groups'] = [];
    group['isDraggable'] = false;
    group['title'] = group.name;
    if (group.group_ids.length > 0 || group.device_ids.length>0) {
      group['isLeaf'] = false;
      group['isExpanded'] = true;
      group['children'] = [];
      for(let i = 0; i < group.group_ids.length; i++) {
        let child = this.parseGroup(group.group_ids[i], null, [...parent, group.id as never]);
        if (child){
          group['children'].push(child);
        }
        // group['groups'][i] = this.parseGroup(group.group_ids[i]);
      }
      for(let i = 0; i < group.device_ids.length; i++) {
        let data = this.parseGroup(id, group.device_ids[i], [...parent, group.id as never]);
        if (data){
          group['children'].push(data);
        }
      }
    } else {
      // isleaf
      group['isLeaf'] = true;
    }
    return group;
  }

  public equals(a, b){
    const strA = a.toString();
    const strB = b.toString();
    if (strA.length == strB.length) return strA == strB;
    return strA.length > strB.length ? strA.substring(0, strB.length) == strB : strB.substring(0, strA.length) == strA;
  }

  public onSelect(event, node){
    const slVueTree = this.$refs.slVueTree;
    event.stopPropagation();
    const checked = !node.data || node.data.checked !== false;

    // update selected path
    (slVueTree as any).updateNode(node.path, {data: {
      id: node.data.id,
      parent:  node.data.parent,
      checked: !checked,
    }});

    // update parent
    let parent = (slVueTree as any).getNode([node.path[0]]);
    
    (slVueTree as any).updateNode([node.path[0]], {data: {
      id: parent.data.id,
      parent:  parent.data.parent,
      checked: !checked,
    }});

    let updated = (slVueTree as any).getNode([node.path[0]]);

    (this.$refs.slVueTree as any).traverse((treeNode, nodeModel, path) => {
      if (treeNode.data.checked !== undefined){
        if (updated.data.checked === false && treeNode.data.parent.includes(updated.data.id)) {
          (slVueTree as any).updateNode(treeNode.path, {data: {
            id: treeNode.data.id,
            parent:  treeNode.data.parent,
            checked: false,
          }});
        }
        else if (treeNode.data.id !== updated.data.id && treeNode.data.parent.includes(updated.data.id) 
          && treeNode.path.length<node.path.length && this.equals(treeNode.path, node.path)){
          (slVueTree as any).updateNode(treeNode.path, {data: {
            id: treeNode.data.id,
            parent:  treeNode.data.parent,
            checked: updated.data.checked,
          }});
        }
      }      
    });

    // // update parent path
    // let currentPath = node.path;
    // let length = currentPath.length;
    // for(let i = 0; i <= length; i++) {
    //     currentPath.pop();
    //     let parent = (slVueTree as any).getNode(currentPath);
    //     (slVueTree as any).updateNode(currentPath, {data: {
    //       id: parent.data.id,
    //       parent:  parent.data.parent,
    //       checked: !checked,
    //     }});
    // }
  }

  public async mounted() {
    await dispatchGetGroups(this.$store, this.getProjectId);
    await dispatchGetDevices(this.$store, this.getProjectId);
    this.qrUser = this.init();
    if(this.groups.length>0){
      this.groups.forEach((group) => {
        this.childGroups.push(...group.group_ids);
        this.childDevices.push(...group.device_ids);
      });

      this.groups.forEach((group) => {
        if (!this.childGroups.includes(group.id as never)){
          this.nodes.push(this.parseGroup(group.id, null, [group.id as never]));
        }
      });

      let devices = [];
      if (this.devices.length>0){
        this.devices.forEach(device => {
          if (!this.childDevices.includes(device['id'] as never)){
            devices.push({
              'title': device['block_name'],
              'isDraggable': false as never,
              'isExpanded': false  as never,
              'isLeaf': true  as never,
              'data': {
                id: device['id'],
                type: device['type'],
                serial: device['serial_number'],
                channels: device['channels'],
              }
            } as never);
          }
        });

        this.nodes.push({
          'title': 'Unassigned',
          'isDraggable': false,
          'isExpanded': false,
          'children': devices,
          'isLeaf': false
        });
      }
    }
  }

  public init() {
    const date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000));
    date.setMonth(new Date().getMonth() + 1);
    return {
      role: 'user',
      expired_at: date.toISOString().substr(0, 10),
      group_ids: [],
      username: this.randomUsername,
    } as IQrUserCreate;
  }

  public reset() {
    this.qrUser = this.init()
  }

  public cancel() {
    this.$router.push(`/main/projects/${this.$router.currentRoute.params.projectId}/detail`);
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      (this.$refs.slVueTree as any).traverse((node, nodeModel, path) => {
        if (node.data.checked === true){
          this.qrUser.group_ids.push(node.data.id as never);
        }
      });
      await dispatchCreateQrUser(this.$store, { 
        projectId: this.$router.currentRoute.params.projectId,
        data: this.qrUser,
      });
      this.$router.push(`/main/projects/${this.$router.currentRoute.params.projectId}/detail`);
    }
  }
}
